import { IGamemasterPokemon } from "../DTOs/IGamemasterPokemon";
import { useLanguage } from "../contexts/language-context";
import "./PokemonMoves.scss"
import { usePokemon } from "../contexts/pokemon-context";
import translator, { TranslatorKeys } from "../utils/Translator";
import gameTranslator, { GameTranslatorKeys } from "../utils/GameTranslator";
import { LeagueType } from "../hooks/useLeague";
import { usePvp } from "../contexts/pvp-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ListEntry from "./ListEntry";
import PokemonImage from "./PokemonImage";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageSource, useImageSource } from "../contexts/imageSource-context";
import { computeDPSEntry, shortName, translateMoveFromMoveId } from "../utils/pokemon-helper";
import { useMoves } from "../contexts/moves-context";
import { MatchUp } from "../DTOs/IRankedPokemon";
import { useGameTranslation } from "../contexts/gameTranslation-context";
import { ConfigKeys, readPersistentValue, writePersistentValue } from "../utils/persistent-configs-handler";
import useResize from "../hooks/useResize";
import { DPSEntry } from "../contexts/raid-ranker-context";
import LoadingRenderer from "./LoadingRenderer";

interface IPokemonCounters {
    pokemon: IGamemasterPokemon;
    league: LeagueType;
}

const parsePersistentCachedNumberValue = (key: ConfigKeys, defaultValue: number) => {
    const cachedValue = readPersistentValue(key);
    if (!cachedValue) {
        return defaultValue;
    }
    return +cachedValue;
}

const parsePersistentCachedBooleanValue = (key: ConfigKeys, defaultValue: boolean) => {
    const cachedValue = readPersistentValue(key);
    if (cachedValue === null) {
        return defaultValue;
    }
    return cachedValue === "true";
}

const PokemonCounters = ({pokemon, league}: IPokemonCounters) => {
    const [shadow, setShadow] = useState(parsePersistentCachedBooleanValue(ConfigKeys.Shadow, true));
    const [mega, setMega] = useState(parsePersistentCachedBooleanValue(ConfigKeys.Mega, true));
    const [top, setTop] = useState(parsePersistentCachedNumberValue(ConfigKeys.ShowEntries, 10));
    const {currentLanguage, currentGameLanguage} = useLanguage();
    const {gamemasterPokemon, fetchCompleted, errors} = usePokemon();
    const {gameTranslation, gameTranslationFetchCompleted, gameTranslationErrors} = useGameTranslation();
    const {rankLists, pvpFetchCompleted, pvpErrors} = usePvp();
    const {moves, movesFetchCompleted, movesErrors} = useMoves();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {x} = useResize();
    const {imageSource} = useImageSource();

    const resourcesNotReady = useMemo(() => !fetchCompleted || !gameTranslationFetchCompleted || !movesFetchCompleted || !pvpFetchCompleted || !gamemasterPokemon || !pokemon
    , [fetchCompleted, gameTranslationFetchCompleted, gamemasterPokemon, movesFetchCompleted, pokemon, pvpFetchCompleted]);
    
    useEffect(() => {
        writePersistentValue(ConfigKeys.ShowEntries, top.toString());
    }, [top]);

    useEffect(() => {
        writePersistentValue(ConfigKeys.Mega, mega.toString());
    }, [mega]);

    useEffect(() => {
        writePersistentValue(ConfigKeys.Shadow, shadow.toString());
    }, [shadow]);

    const comparisons = useMemo(() => {
        if (resourcesNotReady) {
            return [];
        }

        const comparisons: DPSEntry[] = [];
        Object.values(gamemasterPokemon)
            .filter(p => !p.aliasId)
            .forEach(p => comparisons.push(computeDPSEntry(p, gamemasterPokemon, moves, 15, 100, "", pokemon)));

        return comparisons.sort((e1: DPSEntry, e2: DPSEntry) => {
            if (e2.dps !== e1.dps) {
                return e2.dps - e1.dps;
            }
            
            return e1.speciesId.localeCompare(e2.speciesId);
        });
    }, [resourcesNotReady, gamemasterPokemon, moves, pokemon]);

    const greatLeagueMatchUps = useMemo(() => resourcesNotReady ? [] : rankLists[0][pokemon.speciesId]?.matchups ?? [], [rankLists, pokemon, resourcesNotReady]);
    const greatLeagueCounters = useMemo(() => resourcesNotReady ? [] : rankLists[0][pokemon.speciesId]?.counters ?? [], [rankLists, pokemon, resourcesNotReady]);
    const ultraLeagueMatchUps = useMemo(() => resourcesNotReady ? [] : rankLists[1][pokemon.speciesId]?.matchups ?? [], [rankLists, pokemon, resourcesNotReady]);
    const ultraLeagueCounters = useMemo(() => resourcesNotReady ? [] : rankLists[1][pokemon.speciesId]?.counters ?? [], [rankLists, pokemon, resourcesNotReady]);
    const masterLeagueMatchUps = useMemo(() => resourcesNotReady ? [] : rankLists[2][pokemon.speciesId]?.matchups ?? [], [rankLists, pokemon, resourcesNotReady]);
    const masterLeagueCounters = useMemo(() => resourcesNotReady ? [] : rankLists[2][pokemon.speciesId]?.counters ?? [], [rankLists, pokemon, resourcesNotReady]);
    const customLeagueMatchUps = useMemo(() => resourcesNotReady ? [] : rankLists[3] ? (rankLists[3][pokemon.speciesId]?.matchups ?? []) : [], [rankLists, pokemon, resourcesNotReady]);
    const customLeagueCounters = useMemo(() => resourcesNotReady ? [] : rankLists[3] ? (rankLists[3][pokemon.speciesId]?.counters ?? []) : [], [rankLists, pokemon, resourcesNotReady]);

    const relevantMatchUps = useMemo(() => league === LeagueType.GREAT_LEAGUE ? greatLeagueMatchUps : league === LeagueType.ULTRA_LEAGUE ? ultraLeagueMatchUps : league === LeagueType.CUSTOM_CUP ? customLeagueMatchUps : masterLeagueMatchUps, [customLeagueMatchUps, greatLeagueMatchUps, league, masterLeagueMatchUps, ultraLeagueMatchUps]);
    const relevantCounters = useMemo(() => league === LeagueType.GREAT_LEAGUE ? greatLeagueCounters : league === LeagueType.ULTRA_LEAGUE ? ultraLeagueCounters : league === LeagueType.CUSTOM_CUP ? customLeagueCounters : masterLeagueCounters, [customLeagueCounters, greatLeagueCounters, league, masterLeagueCounters, ultraLeagueCounters]);

    const leagueName = useMemo(() => gameTranslator(league === LeagueType.GREAT_LEAGUE ? GameTranslatorKeys.GreatLeague : league === LeagueType.ULTRA_LEAGUE ? GameTranslatorKeys.UltraLeague : league === LeagueType.MASTER_LEAGUE ? GameTranslatorKeys.MasterLeague : league === LeagueType.CUSTOM_CUP ? GameTranslatorKeys.FantasyCup : GameTranslatorKeys.Raids, currentGameLanguage), [currentGameLanguage, league]);

    const renderPvPEntry = useCallback((pokemon: IGamemasterPokemon, score: number, className: string) => {
        const type1 = pokemon.types[0];
        const type2 = pokemon.types.length > 1 ? pokemon.types[1] : undefined;

        return <ListEntry
        slimmer
        slim
            mainIcon={
                {
                    imageDescription: pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage)),
                    image: <div className={`${imageSource !== ImageSource.Official ? "" : "img-small-padding"}`}><PokemonImage pokemon={pokemon} specificHeight={imageSource !== ImageSource.Official ? 28 : 24} specificWidth={imageSource !== ImageSource.Official ? 28 : 24} withName={false}/></div>,
                    imageSideText: shortName(pokemon.speciesName),
                    withBackground: true
                }
            }
            backgroundColorClassName={className}
            secondaryContent={[
                <React.Fragment key={pokemon.speciesId}>
                    {score >= 500 ? <span className="win with-shadow with-brightness">{score / 10}%</span> : <span className="lose with-shadow with-brightness">{score / 10}%</span>}
                </React.Fragment>
            ]}
            onClick={() => navigate(`/pokemon/${pokemon.speciesId}${pathname.substring(pathname.lastIndexOf("/"))}`)}
            specificBackgroundStyle={`linear-gradient(45deg, var(--type-${type1}) 72%, var(--type-${type2 ??  type1}) 72%)`}
        />
    }, [currentGameLanguage, imageSource, navigate, pathname]);

    const detailsClickHandler = useCallback((e: MouseEvent, elementId: string) => {
        const details = document.getElementById(elementId) as HTMLDetailsElement;
        if (details) {
            details.open = !details.open;
            e.stopPropagation();
            e.preventDefault();
        }
    }, []);

    const renderBuffDetailItem = useCallback((moveId: string, attack: number, speciesId: string) => {
        return {
            detailId: `${moveId}-${speciesId}`,
            onClick: (event: any) => detailsClickHandler(event, `${moveId}-${speciesId}`),
            summary: <>
                <img alt="Special effects" loading="lazy" width="14" height="14" decoding="async" src={`${process.env.PUBLIC_URL}/images/types/${moves[moveId].type}.png`}/>
                <span>{translateMoveFromMoveId(moveId, moves, gameTranslation)}</span>
            </>,
            content: <>
                <p>
                    <strong className="move-detail no-extra-padding">
                        {attack}
                        <img className="invert-light-mode" alt="damage" src="https://i.imgur.com/uzIMRdH.png" width={14} height={16}/>
                        {Math.abs(moves[moveId].pveEnergyDelta)}
                        <img className="invert-light-mode" alt="energy gain" src="https://i.imgur.com/Ztp5sJE.png" width={11} height={16}/>
                        {moves[moveId].pveDuration}s
                        <img className="invert-light-mode" alt="cooldown" src="https://i.imgur.com/RIdKYJG.png" width={11} height={16}/>
                    </strong>
                </p> 
            </>
        }
    }, [detailsClickHandler, gameTranslation, moves])

    const renderRaidEntry = useCallback((pokemon: IGamemasterPokemon, dps: number, fastMove: string, chargedMove: string, className: string, fastMoveDamage: number, chargedMoveDamage: number) => {
        const type1 = pokemon.types[0];
        const type2 = pokemon.types.length > 1 ? pokemon.types[1] : undefined;

        return <ListEntry
        slimmer
        slim
            mainIcon={
                {
                    imageDescription: pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage)),
                    image: <div className={`${imageSource !== ImageSource.Official ? "" : "img-small-padding"}`}><PokemonImage pokemon={pokemon} specificHeight={imageSource !== ImageSource.Official ? 28 : 24} specificWidth={imageSource !== ImageSource.Official ? 28 : 24} withName={false}/></div>,
                    imageSideText: shortName(pokemon.speciesName),
                    withBackground: true
                }
            }
            backgroundColorClassName={className}
            secondaryContent={[
                <React.Fragment key={pokemon.speciesId}>
                    {<span className="with-shadow with-brightness">{Math.round(dps * 100) / 100} DPS</span>}
                </React.Fragment>
            ]}
            onClick={() => navigate(`/pokemon/${pokemon.speciesId}${pathname.substring(pathname.lastIndexOf("/"))}`)}
            specificBackgroundStyle={`linear-gradient(45deg, var(--type-${type1}) 72%, var(--type-${type2 ??  type1}) 72%)`}
            details={[renderBuffDetailItem(fastMove, fastMoveDamage, pokemon.speciesId), renderBuffDetailItem(chargedMove, chargedMoveDamage, pokemon.speciesId)]}
        />
    }, [currentGameLanguage, imageSource, navigate, pathname, renderBuffDetailItem])

    return (
        <LoadingRenderer errors={gameTranslationErrors + pvpErrors + movesErrors + errors} completed={!resourcesNotReady}>
            {!resourcesNotReady && <div className="banner_layout normal-text">
                {league === LeagueType.RAID &&
                    <div className="extra-ivs-options item default-padding block-column">
                        <div className="centered">
                        <span className="with-padding">
                            {translator(TranslatorKeys.In, currentLanguage).substring(0, 1).toLocaleUpperCase() + translator(TranslatorKeys.In, currentLanguage).substring(1)} {gameTranslator(GameTranslatorKeys.Raids, currentGameLanguage)}, {translator(TranslatorKeys.RaidsIntro, currentLanguage)} {pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage))}:
                        </span></div>
                        <br/>
                        <div className="justified">
                            {translator(TranslatorKeys.Show, currentLanguage)}&nbsp;<select value={top} onChange={e => setTop(+e.target.value)} className="select-level">
                                <option key={10} value={10}>{10}</option>
                                <option key={20} value={20}>{20}</option>
                                <option key={30} value={30}>{30}</option>
                                <option key={50} value={50}>{50}</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mega <input type="checkbox" checked={mega} onChange={() => setMega(p => !p)}/>&nbsp;&nbsp;
                            {gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage)} <input type="checkbox" checked={shadow} onChange={() => setShadow(p => !p)}/>
                        </div>
                    </div>
                }
                {league !== LeagueType.RAID && <div className="centered item default-padding"><span className="with-padding">
                    {translator(TranslatorKeys.TopKeyCountersIntro, currentLanguage)} {pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage))} {translator(TranslatorKeys.In, currentLanguage)} {gameTranslator(league === LeagueType.GREAT_LEAGUE ? GameTranslatorKeys.GreatLeague : league === LeagueType.ULTRA_LEAGUE ? GameTranslatorKeys.UltraLeague : league === LeagueType.CUSTOM_CUP ? GameTranslatorKeys.FantasyCup : GameTranslatorKeys.MasterLeague, currentGameLanguage)}:
                </span></div>}
                <div className="counters-display-layout">
                    {league !== LeagueType.RAID && <div className="menu-item">
                        <div className={`moves-title all-moves fast-moves-section`}>
                            <h3>
                                {`${shortName(pokemon.speciesName)} ${translator(TranslatorKeys.StrongAgainst, currentLanguage)} (${leagueName}):`}
                            </h3>
                        </div>
                        <ul className={`moves-list no-padding slim-list`}>
                            {rankLists[league as number][pokemon.speciesId] ? relevantMatchUps.length > 0 ?
                                relevantMatchUps
                                .map(m => {
                                    const pokemon = gamemasterPokemon[m.speciesId];
                                    const className = `background-${pokemon.types[0].toString().toLocaleLowerCase()}`;
                                    return (
                                        <React.Fragment key={m.speciesId}>
                                            {renderPvPEntry(pokemon, m.rating, className)}
                                        </React.Fragment>
                                    )
                                }) : <span className="centered">{translator(TranslatorKeys.NoResults, currentLanguage)}</span> :
                                <span className="unavailable_moves">
                                    {pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage))} {translator(TranslatorKeys.UnrankedPokemonForLeague, currentLanguage)} {gameTranslator(league === LeagueType.GREAT_LEAGUE ? GameTranslatorKeys.GreatLeague : league === LeagueType.ULTRA_LEAGUE ? GameTranslatorKeys.UltraLeague : league === LeagueType.CUSTOM_CUP ? GameTranslatorKeys.FantasyCup : GameTranslatorKeys.MasterLeague, currentGameLanguage)}
                                </span>
                            }
                        </ul>
                    </div>}
                    <div className="menu-item">
                        <div className={`moves-title all-moves charged-moves-section`}>
                            <h3>
                                {league === LeagueType.RAID ? `${shortName(pokemon.speciesName)} - ${translator(TranslatorKeys.CountersWeak, currentLanguage)}${x > 750 ? ` (1 - ${top / 2}):` : ":"}` : `${shortName(pokemon.speciesName)} ${translator(TranslatorKeys.WeakAgainst, currentLanguage)} (${leagueName}):`}
                            </h3>
                        </div>
                        <ul className={`moves-list no-padding slim-list`}>
                            {league === LeagueType.RAID ?
                                comparisons
                                .filter(o => (shadow || !gamemasterPokemon[o.speciesId].isShadow) && (mega || !gamemasterPokemon[o.speciesId].isMega))
                                .slice(0, x > 750 ? top / 2 : top)
                                .map(m => {
                                    const pokemon = gamemasterPokemon[m.speciesId];
                                    const className = `background-${pokemon.types[0].toString().toLocaleLowerCase()}`;
                                    return (
                                        <React.Fragment key={m.speciesId}>
                                            {renderRaidEntry(pokemon, (m as DPSEntry).dps, (m as DPSEntry).fastMove, (m as DPSEntry).chargedMove, className, (m as DPSEntry).fastMoveDmg, (m as DPSEntry).chargedMoveDmg)}
                                        </React.Fragment>
                                    )
                                }) :
                                rankLists[league as number][pokemon.speciesId] ? relevantCounters.length > 0 ?
                                relevantCounters
                                .map(m => {
                                    const pokemon = gamemasterPokemon[m.speciesId];
                                    const className = `background-${pokemon.types[0].toString().toLocaleLowerCase()}`;
                                    return (
                                        <React.Fragment key={m.speciesId}>
                                            {renderPvPEntry(pokemon, (m as MatchUp).rating, className)}
                                        </React.Fragment>
                                    )
                                }) : <span className="centered">{translator(TranslatorKeys.NoResults, currentLanguage)}</span> :
                                <span className="unavailable_moves">
                                    {pokemon.speciesName.replace("Shadow", gameTranslator(GameTranslatorKeys.Shadow, currentGameLanguage))} {translator(TranslatorKeys.UnrankedPokemonForLeague, currentLanguage)} {gameTranslator(league === LeagueType.GREAT_LEAGUE ? GameTranslatorKeys.GreatLeague : league === LeagueType.ULTRA_LEAGUE ? GameTranslatorKeys.UltraLeague : league === LeagueType.CUSTOM_CUP ? GameTranslatorKeys.FantasyCup : GameTranslatorKeys.MasterLeague, currentGameLanguage)}
                                </span>
                            }
                        </ul>
                    </div>
                    {x > 750 && league === LeagueType.RAID && <div className="menu-item">
                        <div className={`moves-title all-moves charged-moves-section`}>
                            <h3>
                                {`${shortName(pokemon.speciesName)} - ${translator(TranslatorKeys.CountersWeak, currentLanguage)} (${top / 2 + 1} - ${top}):`}
                            </h3>
                        </div>
                        <ul className={`moves-list no-padding slim-list`}>
                            {
                                comparisons
                                .filter(o => (shadow || !gamemasterPokemon[o.speciesId].isShadow) && (mega || !gamemasterPokemon[o.speciesId].isMega))
                                .slice(top / 2, top)
                                .map(m => {
                                    const pokemon = gamemasterPokemon[m.speciesId];
                                    const className = `background-${pokemon.types[0].toString().toLocaleLowerCase()}`;
                                    return (
                                        <React.Fragment key={m.speciesId}>
                                            {renderRaidEntry(pokemon, (m as DPSEntry).dps, (m as DPSEntry).fastMove, (m as DPSEntry).chargedMove, className, (m as DPSEntry).fastMoveDmg, (m as DPSEntry).chargedMoveDmg)}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </ul>
                    </div>}
                </div>
            </div>}
        </LoadingRenderer>
    );
}

export default PokemonCounters;